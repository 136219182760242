import '../Css/style.css';
import Lenis from '@studio-freight/lenis';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Rellax from 'rellax';
import * as AOS from "aos";
AOS.init();
AOS.refresh();
if (window.innerWidth >= 768) {
    var rellax = new Rellax('.rellax', {
        center: true
    });
}
window.addEventListener('resize', function () {
    if (window.innerWidth <= 768) {
        if (rellax) {
            rellax.destroy();
        }
    }
});
//Fancybox for Lightbox usage
Fancybox.bind('[data-fancybox="gallery"]', {});
//Lenis for Smooth Scroll
document.addEventListener('DOMContentLoaded', function (event) {
    var lenis = new Lenis({
        duration: 1.3,
        easing: function (t) { return Math.min(1, 1.001 - Math.pow(2, -10 * t)); },
    });
    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
    document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
        if (anchor.getAttribute('href') !== '#') {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                lenis.scrollTo(this.getAttribute('href'));
                var target = document.querySelector(this.getAttribute('href'));
                target.setAttribute('tabindex', '-1');
                target.focus({ preventScroll: true, focusVisible: false });
                target.addEventListener('blur', function () {
                    target.removeAttribute('tabindex');
                });
                target.addEventListener('focusout', function () {
                    target.removeAttribute('tabindex');
                });
            });
        }
    });
    var btt = document.querySelector('.back-to-top');
    if (btt) {
        btt.addEventListener('click', function () {
            lenis.scrollTo('top');
        });
    }
});
//end of Lenis
// Navigation Opener
var hasSubMenus = document.querySelectorAll('.has-sub');
hasSubMenus.forEach(function (hasSubMenu) {
    var subMenu = hasSubMenu.querySelector('.sub-menu');
    if (subMenu) {
        hasSubMenu.addEventListener('click', function () {
            subMenu.classList.toggle('open');
        });
    }
});
//scroll checker
function addScrollDirection(element) {
    var lastScrollTop = 0;
    document.addEventListener('scroll', function (e) {
        var scrollTop = window.scrollY || document.documentElement.scrollTop;
        if (scrollTop > lastScrollTop) {
            element.classList.add('scrolling-down');
            element.classList.remove('scrolling-up');
        }
        else if (scrollTop != lastScrollTop) {
            element.classList.remove('scrolling-down');
            element.classList.add('scrolling-up');
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    });
}
addScrollDirection(document.body);
//scroll class for navigation
var navigationScrollEvent = function (navigation) {
    var checkScroll = function () {
        var top = window.pageYOffset || document.documentElement.scrollTop;
        if (top > 1) {
            navigation.classList.add("scrolling");
        }
        else {
            navigation.classList.remove("scrolling");
        }
    };
    checkScroll();
    window.addEventListener('scroll', checkScroll);
};
document.addEventListener('DOMContentLoaded', function () {
    var navigation = document.getElementById('navigation');
    if (navigation) {
        navigationScrollEvent(navigation);
    }
});
// function pageTransition() {
//     var tl = gsap.timeline();
//     tl.to('.transition-barba-full', { duration: .5, scaleY: 1, transformOrigin: "bottom", ease: "power4.inOut" });
//     tl.to('.transition-barba-full', { duration: .5, scaleY: 0, transformOrigin: "top", ease: "power4.inOut", delay: .2 });
//     // tl.to('ul.transition-barba li', { duration: .5, scaleX: 1, transformOrigin: "top right", stagger: .2 });
//     // tl.to('ul.transition-barba li', { duration: .5, scaleX: 0, transformOrigin: "top right", stagger: .1, delay: .1 });
// }
// function contentAnimation() {
//     var tl = gsap.timeline();
//     tl.to('.transition-barba-full', { duration: .5, scaleY: 1, transformOrigin: "bottom", ease: "power4.inOut" });
//     tl.to('.transition-barba-full', { duration: .5, scaleY: 0, transformOrigin: "top", ease: "power4.inOut", delay: .2 });
//     // tl.to('ul.transition-barba li', { duration: .5, scaleX: 1, transformOrigin: "top right", stagger: .2 });
//     // tl.to('ul.transition-barba li', { duration: .5, scaleX: 0, transformOrigin: "top right", stagger: .1, delay: .1 });
// }
// function delay(n: number) {
//     n = n || 0;
//     return new Promise((done) => {
//         setTimeout(() => {
//             done(undefined);
//         }, n);
//     });
// }
// barba.init({
//     transitions: [{
//         async leave(data) {
//             const done = this.async();
//             pageTransition();
//             await delay(500);
//             done();
//         },
//         async enter(data) {
//             contentAnimation();
//         },
//         async once(data) {
//             contentAnimation();
//         }
//     }]
// });
var countersec = document.querySelectorAll('.number');
var observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting) {
            var animate_1 = function () {
                var value = +entry.target.getAttribute('data-count');
                var data = +entry.target.innerText;
                var time = value / 100;
                if (data < value) {
                    entry.target.innerText = Math.ceil(data + time).toString();
                    setTimeout(animate_1, 50);
                }
            };
            animate_1();
        }
    });
}, { threshold: 0.6 });
countersec.forEach(function (counter) {
    observer.observe(counter);
});
// const counters = document.querySelectorAll('.number');
// const speed = 1000;
// counters.forEach( counter => {
//      const animate = () => {
//             const value = +counter.getAttribute('data-count');
//             const data = +(<HTMLElement>counter).innerText;
//             const time = value / speed;
//          if(data < value) {
//                     (<HTMLElement>counter).innerText = Math.ceil(data + time).toString();
//                     setTimeout(animate, 1);
//                 }else{
//                     (<HTMLElement>counter).innerText = value.toString();
//                 }
//      }
//      animate();
// });
